"use client";

import DefaultButton from "@/components/DefaultButton";
import Image from "next/image";
import { createContext, useContext, useState } from "react";
import PhoneInput from "react-phone-input-2";

const ModalContactContext = createContext(null);

export function useModalContact() {
    return useContext(ModalContactContext);
}

export default function ModalContactProvider({ children }) {
    const [modal, setModal] = useState({
        state: false,
        where: false,
    });
    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");

    function openModal(from = false) {
        setModal((prev) => ({
            state: true,
            where: from,
        }));
    }

    function closeModal() {
        setModal((prev) => ({
            state: false,
            where: false,
        }));
    }

    return (
        <ModalContactContext.Provider value={{ openModal, closeModal }}>
            {children}
            {modal.state ? (
                <div className="modal-contact">
                    <div className="closeLayer" onClick={closeModal} />
                    <div className="modal-conainer">
                        <div className="img">
                            <Image
                                src="/src/imgs/contact_form.png"
                                alt="contact_form"
                                fill={true}
                                sizes={"100%"}
                            />
                        </div>
                        <div className="right">
                            <h2>Оставь контакты</h2>
                            <p>И мы свяжемся с тобой</p>
                            <div className="form">
                                <div className="inputs">
                                    <input
                                        type="text"
                                        placeholder="Имя"
                                        value={name}
                                        pattern={"[A-zА-я]{0,12}"}
                                        onChange={({ target }) => {
                                            if (!target.validity.valid) return;

                                            setName(target.value);
                                        }}
                                    />
                                    <PhoneInput
                                        country={"ru"}
                                        value={phone}
                                        onChange={(phone) => setPhone(phone)}
                                        containerClass="forms-input"
                                        dropdownClass="forms-dropdown"
                                    />
                                    <DefaultButton
                                        text="Отправить"
                                        className="btn"
                                        arrow={false}
                                        disable={!phone || !name}
                                        cb={{
                                            type: "noty",
                                            args: `Номер: ${phone}\nИмя: ${name}\n\n${modal.where}`,
                                            modal: true,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}
        </ModalContactContext.Provider>
    );
}
