"use client";
import { useState, useEffect } from "react";

import HeroBlock from "@/components/HeroPage/new_one/Hero";
import Youtubs from "@/components/HeroPage/new_one/Youtubs";
import ProductSpinner from "@/components/shared/ProductSpinner";

export default function NewMainPage() {
    const [DynamicComponent, setDynamicComponent] = useState(null);

    useEffect(() => {
        const loadComponent = async () => {
            const { default: LoadedComponent } = await import(
                "@/components/HeroPage/new_one/Compose"
            );
            setDynamicComponent(() => LoadedComponent);
        };

        const handleUserAction = () => {
            if (!DynamicComponent) {
                loadComponent();

                document.removeEventListener("click", handleUserAction);
                document.removeEventListener("keydown", handleUserAction);
                document.removeEventListener("mousemove", handleUserAction);
                document.removeEventListener("scroll", handleUserAction);
                document.removeEventListener("touchstart", handleUserAction);
            }
        };

        if (process.env.NODE_ENV === "development") {
            loadComponent();
        } else {
            document.addEventListener("click", handleUserAction);
            document.addEventListener("keydown", handleUserAction);
            document.addEventListener("mousemove", handleUserAction);
            document.addEventListener("scroll", handleUserAction);
            document.addEventListener("touchstart", handleUserAction);
        }

        return () => {
            document.removeEventListener("click", handleUserAction);
            document.removeEventListener("keydown", handleUserAction);
            document.removeEventListener("mousemove", handleUserAction);
            document.removeEventListener("scroll", handleUserAction);
            document.removeEventListener("touchstart", handleUserAction);
        };
    }, [DynamicComponent]);

    return (
        <>
            <div className="new-hero-page">
                <HeroBlock />
                <div className="main-container">
                    <Youtubs />
                    {DynamicComponent ? (
                        <DynamicComponent />
                    ) : (
                        <div className="padd-container">
                            <div
                                className="white-bordered"
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <ProductSpinner />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}
