import Image from "next/image";
import HeroImg from "@/imgs/catalog/auto_bg_catalog.jpg";
import HeroInputs from "@/components/shared/HeroInputs";

export default function HeroBlock() {
    return (
        <div className="hero-block">
            <div className="bg">
                <Image
                    src={HeroImg.src}
                    fill
                    priority={true}
                    placeholder="blur"
                    alt="main bg"
                    blurDataURL={HeroImg.blurDataURL}
                />
            </div>
            <div className="hero-block__content">
                <h1 className="hero-block__title">Авто из США</h1>
                <div className="hero-block__subtitle">
                    <p>
                        Американская мечта <br />
                        dodge challenger
                    </p>
                    <span>$ 15 000</span>
                </div>
                <HeroInputs />
            </div>
        </div>
    );
}
