"use client";

import DefaultButton from "@/components/DefaultButton";
import { useState } from "react";
import PhoneInput from 'react-phone-input-2'

export default function HeroInputs({ animate = true }) {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");

    return (
        <div className="forms">
            <input
                type="text"
                placeholder="Имя"
                onChange={handleNameChange}
                value={name}
            />
            <PhoneInput
                country={'ru'}
                value={phone}
                onChange={(phone) => setPhone(phone)}
                containerClass="forms-input"
                dropdownClass="forms-dropdown"
            />
            <DefaultButton
                text="Хочу авто из США"
                className={`btn ${animate ? "btn-anim" : ""}`}
                disable={!phone || !name}
                arrow={false}
                cb={{
                    type: "noty",
                    args: `Быстрая форма на главной (первый блок)\n\nНомер: ${phone} \nИмя: ${name}`,
                }}
            />
        </div>
    );
    function handleNameChange(e) {
        setName(e.target.value);
    }
    function handlePhoneChange(e) {
        setPhone(e.target.value);
    }
}
