"use client";
import TelegramSvg from "@/svg/socials/telega.svg";
import InstagramSvg from "@/svg/socials/insta.svg";
import YoutubeSvg from "@/svg/socials/youtube.svg";
import { useEffect, useState } from "react";
import Image from "next/image";
import ytImgBg from "@/imgs/yt_bg.avif";
import LazyLoadYouTube from "@/components/shared/LazyLoadVideo";

export default function Youtubs() {
    const [socials, setSocials] = useState([]);

    const { width, height, blurHeight, blurWidth, ...bg_img_settings } =
        ytImgBg;

    useEffect(() => {
        async function fetchContacts() {
            const url = "/api/contacts";
            const response = await fetch(url);
            const data = await response.json();
            if (!data) return;
    
            const filters = ["YouTube", "Instagram", "Telegram"];
            const socials = data.socialsData.filter((item) =>
                filters.includes(item.type)
            );
            setSocials(socials);
        }
        
        fetchContacts();
    }, []);

    const yt_links = [
        {
            title: "Гордей и его новый автомобиль от Auto-desk",
            link: "https://www.youtube.com/embed/V4l_UOLCGmE?si=NCLTb6ScV9fDNwCu",
            videoId: "V4l_UOLCGmE",
        },
        {
            title: "Эрик давыдыч о auto desk",
            link: "https://www.youtube.com/embed/c1IkYzk_044?si=e0zkWzAYym5IWe-k",
            videoId: "c1IkYzk_044",
        },
        {
            title: "Доставили М3 булкину",
            link: "https://www.youtube.com/embed/4sGnGhmkKYw?si=Y0FLQOlfwciA29G-",
            videoId: "4sGnGhmkKYw",
        },
    ];

    return (
        <div className="youtubs_block">
            <div className="padd-container">
                <div className="white-bordered">
                    <div className="bg">
                        <Image {...bg_img_settings} fill  />
                    </div>
                    <div className="youtubs_block__title">
                        <h2>Что о нас говорят?</h2>
                        <div className="block">
                            <p>Больше Вы можете< br/>  посмотреть в наших соцсетях </p>

                            <div className="btns">{renderSocials()}</div>
                        </div>
                    </div>
                    <div className="youtubs_block__content">
                        {renderVideos()}
                    </div>
                </div>
            </div>
        </div>
    );

    function renderVideos() {
        return yt_links.map((data, i) => {
            return (
                <div className="youtubs_block__item" key={i}>
                    <LazyLoadYouTube videoId={data.videoId} />
                    <div className="v_title">{data.title}</div>
                </div>
            );
        });
    }
    function renderSocials() {
        const icons = {
            Telegram: <TelegramSvg />,
            Instagram: <InstagramSvg />,
            YouTube: <YoutubeSvg />,
        };
        return socials.map((item) => (
            <a href={item.link} target="_blank" key={item._id}>
                {icons[item.type]}
            </a>
        ));
    }
}
